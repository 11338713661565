'use client'

import { useSession } from 'next-auth/react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { createNewChat, generateSystemPromptText } from '@/components/Chat/chat.utils'
import { useClientConfig } from '@/components/Chat/useConfig'

interface ChatbotProviderProps extends React.PropsWithChildren {}

interface ChatbotContextProps {
  initialPrompt: string | undefined
  setInitialPrompt: (initialPromptValue: string) => void
  refreshChat: () => void
  chatId: string | undefined
  isLoading: boolean
}

export const ChatbotContext = createContext<ChatbotContextProps>({
  initialPrompt: undefined,
  setInitialPrompt: () => null,
  refreshChat: () => null,
  chatId: undefined,
  isLoading: false,
})

export const ChatbotProvider = ({ children }: ChatbotProviderProps) => {
  const { chatAPI } = useClientConfig()
  const [systemPrompts, setSystemPrompts] = useState<{ [key: string]: { text: string } }>({})

  const [chatId, setChatId] = useState(crypto.randomUUID())
  const [isLoading, setIsLoading] = useState(false)

  const [initialPrompt, setInitialPrompt] = useState<string | undefined>(undefined)
  const { data: session } = useSession()

  const [isChatCreated, setIsChatCreated] = useState<boolean>(false)
  const [isSystemPromptCreated, setIsSystemPromptCreated] = useState<boolean>(false)

  const systemPromptName = useMemo(() => {
    // Email is used for generating system prompt,
    // because it should be unique
    const email = session?.user.email

    const role = session?.user.role
    if (!email || !role) return undefined
    return `${email} ${role} system prompt`
  }, [session])

  const createSystemPrompt = useCallback(
    (prompts: { [key: string]: { text: string } }) => {
      const name = session?.user.firstName
      const role = session?.user.role
      // We cannot proceed if we do not have user's name and role.
      if (!systemPromptName || !prompts || Object.keys(prompts).length === 0) return
      // We need to check if there is existing system prompt for current user.
      const existingUsersSystemPrompt = Object.keys(prompts).find((key) => key === systemPromptName)
      if (!existingUsersSystemPrompt) {
        // In case there is no created system prompt for user, we should create one.
        // It is being created by using default system prompt with additional
        // info about student's name and role.

        const { text } = prompts.default
        if (!name || !role) return
        const newText = generateSystemPromptText(name, role, text)

        fetch(`${chatAPI}/system-prompt`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text: newText, name: systemPromptName }),
        })
          .then((response) => response.json())
          .then(() => setIsSystemPromptCreated(true))
          .catch((error) => {
            console.error('Error updating config', error)
            setIsLoading(false)
          })
      } else {
        setIsSystemPromptCreated(true)
      }
    },
    [chatAPI, session?.user.firstName, session?.user.role, systemPromptName]
  )

  const fetchAllSystemPrompts = useCallback(() => {
    setIsLoading(true)
    if (Object.keys(systemPrompts).length === 0)
      fetch(`${chatAPI}/system-prompts`)
        .then((response) => response.json())
        .then((data) => {
          setSystemPrompts({ ...data })
          return data
        })
        .then((data) => createSystemPrompt(data))
        .catch((error) => {
          console.error('Error fetching config', error)
          setIsLoading(false)
        })
  }, [chatAPI, createSystemPrompt, systemPrompts])

  useEffect(() => {
    fetchAllSystemPrompts()
  }, [fetchAllSystemPrompts])

  const createChat = useCallback(() => {
    if (!isChatCreated && systemPromptName && isSystemPromptCreated && chatAPI) {
      createNewChat(chatAPI, chatId, systemPromptName)
        .then(() => {
          setIsChatCreated(true)
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.error('Error creating config', error)
          setIsLoading(false)
        })
    }
  }, [chatAPI, chatId, isChatCreated, isSystemPromptCreated, systemPromptName])

  useEffect(() => {
    if (!isChatCreated && systemPromptName && Object.keys(systemPrompts).length > 0) createChat()
  }, [createChat, isChatCreated, systemPromptName, systemPrompts])

  const refreshAvaContent = () => {
    setChatId(crypto.randomUUID())
    setIsChatCreated(false)
  }
  return (
    <ChatbotContext.Provider
      value={{ initialPrompt, setInitialPrompt, refreshChat: refreshAvaContent, chatId, isLoading }}
    >
      {children}
    </ChatbotContext.Provider>
  )
}

export const useChatbot = () => {
  const value = useContext(ChatbotContext)
  return value
}

export default ChatbotContext
