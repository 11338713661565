import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-intl@3.17.2_next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-intl@3.17.2_next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0__react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../fonts/book-antiqua.ttf\",\"display\":\"swap\",\"variable\":\"--font-book-antiqua\"}],\"variableName\":\"bookAntiqua\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Sofia_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-sofia-sans\"}],\"variableName\":\"sofia\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"variable\":\"--font-outfit\",\"subsets\":[\"latin\"]}],\"variableName\":\"outfit\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Caveat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-caveat\"}],\"variableName\":\"caveat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/public/images/cgn-logo-arrow.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/components/GoogleTagManager/GTMEmbed.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/components/GoogleTagManager/GTMHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/components/Header/Impersonation/ImpersonatingHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/components/Header/NavBars/ResponsiveNavBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/ChatbotProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/ContentDataProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/NextAuthProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/RouterHistoryProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/SchoolProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/SettingsProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webapp/src/providers/ThemeProvider.tsx")